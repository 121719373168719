import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router,ActivatedRoute  } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
// import { AuthenticationService } from '../auth/services/authentication.service'; // Import your auth service

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent implements OnInit {
  isMenuCollapsed = true; // Start with the menu collapsed
  user: any;  // Store logged in user
  showHeader: boolean = true;
  email: string | null = null;

  @ViewChild('howItWorks') howItWorksSection!: ElementRef;

  constructor(private router: Router, private authService: AuthenticationService,  private route: ActivatedRoute ) {}

  ngOnInit(): void {
    // Get the logged-in user's data
    this.user = this.authService.userValue; 
    this.route.queryParams.subscribe(params => {
      this.email = params['email'] || null;
    });
    this.router.events.subscribe(() => {
      this.showHeader = !this.router.url.includes('/auth/third-party-login'); 
    }); // Check if user is logged in
  }

  goTosignIn() {
    this.router.navigateByUrl('/auth/sign-in');
  }

  goTosignUp() {
    this.router.navigateByUrl('/auth/sign-up');
  }

  toggleMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
  }

  goTohome() {
    this.router.navigate(['']);
  };
  goToDashboard() {
    // Navigate to the dashboard if the user is logged in
    if (this.user) {
      this.router.navigateByUrl('/dashboard');
    }
  }

  scrollToHowItWorks() {
    this.isMenuCollapsed = true;
    const yOffset = -70;
    const element = document.getElementById('how-it-works');
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });

      this.router.navigate([], {
        fragment: 'how-it-works',
        queryParamsHandling: 'preserve'
      });
    }
  }

  scrollToPricing() {
    this.isMenuCollapsed = true;
    const yOffset = -70;
    const element = document.getElementById('pricing');
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });

      this.router.navigate([], {
        fragment: 'pricing',
        queryParamsHandling: 'preserve'
      });
    }
  }
  scrollToFaqs(){
    this.isMenuCollapsed = true;
    const yOffset = -70;
    const element = document.getElementById('faqs');
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });

      this.router.navigate([], {
        fragment: 'faqs',
        queryParamsHandling: 'preserve'
      });
    }
  
  }
  
  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/auth/sign-in');
  }
}

